<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link v-if="$can('book_cheques.create')" to="/cheques-cycle/cheque-book/create" class="btn btn-primary font-weight-bolder">
              <v-icon>mdi-plus</v-icon>{{ $t('cheque_book.add_cheque_book')}}
        </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>
    
    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row mb-5">
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label>{{$t('cheque_book.bank')}}</label>
                        <multiselect v-model="bank"
                            :placeholder="$t('cheque_book.bank')"
                            label="name"
                            track-by="id"
                            :options="banks"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getBanks($event)">
                        </multiselect>
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label>{{$t('cheque_book.added_by')}}</label>
                        <multiselect v-model="user"
                            :placeholder="$t('cheque_book.added_by')"
                            label="name"
                            track-by="id"
                            :options="users"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getUsers($event)">
                        </multiselect>
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="used">{{$t('cheque_book.used')}}</label>
                        <select name="" id="used" v-model="filters.used" type="text" class="custom-select">
                            <option value="">{{$t('all')}}</option>
                            <option v-for="(row, index) in used_list" :value="row.id" :key="index">{{ row.text }}</option>
                        </select>
                    </div>
                    
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="from_date">{{$t('cheque_book.from_date')}}</label>
                        <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="to_date">{{$t('cheque_book.to_date')}}</label>
                        <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="bank_booking_no">{{$t('cheque_book.bank_booking_no')}}</label>
                        <input v-model="filters.bank_booking_no" type="number" id="bank_booking_no" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label>{{$t('cheque_book.currency')}}</label>
                        <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select" >
                            <option v-for="row in currencies" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="f_status">{{$t('status')}}</label>
                        <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                            <option value="">{{$t('all')}}</option>
                            <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                        </select>
                    </div>
                    
                    <div class="form-group d-inline-flex col-md-4 mt-6 mb-0">
                        <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="status" slot-scope="props">

                <b-form-checkbox  v-if="$can('book_cheques.change_status')"
                    size="lg" @change="changeStatus(props.row.id, props.row.status)"
                    v-model="props.row.status"
                    :name="'check-button'+props.row.id"
                    switch :key="props.row.id">
                </b-form-checkbox>

                <b-form-checkbox  v-else
                    size="lg" :disabled="true"
                    v-model="props.row.status"
                    :name="'check-button'+props.row.id"
                    switch :key="props.row.id">
                </b-form-checkbox>

            </template>

            <template slot="actions" slot-scope="props">
              <v-btn v-if="$can('book_cheques.update')"  icon color="pink" :to="`/cheques-cycle/cheque-book/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
              </v-btn>

                <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('book_cheques.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

                <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/cheques-cycle/cheque-book/view/${props.row.id}`">
                    <v-icon small class="mr-2">mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "index-cheques-cheque-book",
        data() {
            return {
                mainRoute:'chequescycle/book-cheques',
                routeChangeStatus : 'chequescycle/book-cheque/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    bank_booking_no: null,
                    from_date: null,
                    to_date: null,
                    user_id: null,
                    currency_id: null,
                    status: null,
                    used: null,
                    bank_id: null,
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                used_list: [
                    {id: 1, text: this.$t('finished')},
                    {id: 0, text: this.$t('unfinished')},
                ],
                columns: ['bank_booking_no', 'created_date', 'bank_name', 'used_number', 'currency_name', 'status', 'actions'],
                data: [],
                currencies: [],
                banks: [],
                users: [],
                bank: null,
                user: null,

            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            bank: function (val) {
                if (val) {
                    this.filters.bank_id = val.id;
                } else {
                    this.filters.bank_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        bank_booking_no: that.$t('cheque_book.bank_booking_no'),
                        created_date: that.$t('cheque_book.created_date'),
                        bank_name: that.$t('cheque_book.bank_name'),
                        used_number: that.$t('cheque_book.used_number'),
                        currency_name: that.$t('cheque_book.currency'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params})

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cheque_book")}]);
            this.getCurrencies();
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    status: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.bank_booking_no = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.user_id = null;
                this.filters.currency_id = null;
                this.filters.status = null;
                this.filters.used = null;
                this.user= null;
                this.bank = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            getBanks(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/banks`,{params:{filter: filter}}).then((response) => {
                        this.banks = response.data.data;
                    });
                }else{
                    this.banks = [];
                }
            },

            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },

            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

        },
    };
</script>
